import { CSSProperties } from "react"

export function getFluidText(
  [fontSizeMin, fontSizeMax]: [number, number],
  lineHeightProps?: [number, number]
): Pick<CSSProperties, "fontSize" | "lineHeight"> {
  return {
    fontSize: getClampValue(fontSizeMin, fontSizeMax),
    lineHeight: lineHeightProps ? getClampValue(lineHeightProps[0], lineHeightProps[1]) : "100%",
  }
}

/**
 * https://websemantics.uk/tools/responsive-font-calculator/
 * Formula:
 * font-size  = calc(ZZ + ((1vw - XX) * YY))
 *
 * Where:
 *  XX = min_viewport / 100
 *  YY = 100 * (max_font_size - min_font_size) / (max_viewport - min_viewport)
 *      = 100 * font_size_difference / viewport_difference
 *   ZZ = Minimum font-size stated in REM
 */
export function getClampValue(min: number, max: number) {
  const screenMin = parseInt(process.env.CLAMP_SCREEN_MIN as string) || 375
  const screenMax = parseInt(process.env.CLAMP_SCREEN_MAX as string) || 1280
  const remBase = parseInt(process.env.CLAMP_REM_BASE as string) || 16

  const ZZ = min / remBase
  const XX = screenMin / 100
  const YY = (100 * (max - min)) / (screenMax - screenMin)

  return `clamp(${min}px, calc(${ZZ}rem + ((1vw - ${XX}px) * ${YY.toFixed(2)})), ${max}px)`
}
