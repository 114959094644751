import Link from "next/link"

import classNames from "classnames"

import { RightArrowIcon } from "@/lib/components/icons/right-arrow"

interface ButtonProps {
  href: string
  bgColor: "orange" | "green"
  label: string
  className?: string
}

export const CtaButton = ({ href, bgColor, label, className }: ButtonProps) => {
  return (
    <Link
      href={href}
      className={classNames("cta-btn", className, {
        "bg-cta-orange": bgColor === "orange",
        "bg-dark-blue": bgColor === "green",
      })}
    >
      <span className="flex items-center justify-center pl-3 pr-5">
        <RightArrowIcon stroke="black" />
      </span>
      <span className="whitespace-nowrap pr-4 font-semibold">{label}</span>
    </Link>
  )
}
