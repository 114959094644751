import { GetStaticProps } from "next"

import { Layout } from "@/lib/components/layout/Layout"
import { Wysiwyg } from "@/lib/components/shared/Wysiwyg"
import Image from "@/lib/components/shared/app-image"
import { CtaButton } from "@/lib/components/shared/cta-btn"
import { GET_404_PAGE, GET_GLOBAL_FIELDS } from "@/lib/graphql/graphql-queries"
import { WP404Page, WPGlobalFields, WPSeoFields } from "@/lib/models/common"
import { getFluidText } from "@/lib/utils/clamp"
import { query } from "@/lib/utils/graphql"

export const getStaticProps: GetStaticProps<FourOFourProps> = async () => {
  const [globalFields, page] = await Promise.all([
    query<WPGlobalFields>(GET_GLOBAL_FIELDS),
    query<WP404Page>(GET_404_PAGE, { id: process.env.WP_PAGEID_404 }),
  ])

  const { seo } = page.page

  return {
    props: { globalFields, page, seo },
  }
}

interface FourOFourProps {
  globalFields: WPGlobalFields
  page: WP404Page
  seo: WPSeoFields
}

export default function Custom404({ globalFields, page, seo }: FourOFourProps) {
  const { heading, description, buttonLabel, buttonLink, image } = page["page"]["acf404Page"]

  return (
    <Layout globalFields={globalFields} seo={seo}>
      <section className="w-screen bg-light-beige pt-10 lg:pb-10 lg:pt-0">
        <div className="wrapper relative flex flex-col items-center lg:flex-row">
          <div className="flex flex-1 flex-col">
            <h1 className="mb-6 font-semibold" style={getFluidText([36, 60], [44, 72])}>
              {heading}
            </h1>
            <Wysiwyg content={description} className="max-w-[530px]" />
            <CtaButton
              label={buttonLabel}
              href={buttonLink}
              bgColor="orange"
              className="mt-6 w-fit"
            />
          </div>
          <div className="aspect-h-1 aspect-w-1 relative w-full flex-1 md:aspect-w-2">
            {image && (
              <Image src={image.mediaItemUrl} alt={image.altText} fill className="object-contain" />
            )}
          </div>
        </div>
      </section>
    </Layout>
  )
}
